import React from 'react';
import { useTranslation } from 'react-i18next';

import ImageProvider from '@core/providers/image/image-provider';

import { User } from '@models/user';

type PresenterProps = {
  presenters: User[];
};

export default function Presenters({ presenters }: PresenterProps): React.ReactElement {
  const { t } = useTranslation();

  if (!presenters || presenters.length <= 0) {
    return <></>;
  }

  return (
    <div className="presenters-event mt-4 w-100">
      <h4>{t('Presenters')}</h4>
      {presenters.map((presenter: User) => {
        const { picture } = presenter.relationships;
        const { firstname, lastname, role } = presenter;

        if (!firstname || !lastname) {
          return <></>;
        }

        return (
          <div key={presenter.id} className="flex flex-column align-items-center mt-4 border-bottom">
            {picture && (
              <ImageProvider className="image-presenter-event" fileName={picture.name} alt="" />
            )}

            <h5 className="mt-3">{`${firstname} ${lastname}`}</h5>
            <span className="text-gray">{role}</span>
            <p />
          </div>
        );
      })}
    </div>
  );
}
